import React, { useEffect } from "react"
import styled from "styled-components"

import Layout from "../components/common/layout/layout"
import { graphql, Link } from "gatsby"
import { AccentText, Container, Section } from "../components/global"

const PrivacyPage = (props) => {
  useEffect(() => {
    const wrap = document.getElementById("cookies-declaration-wrap")
    const script = document.createElement("script")
    script.id = "CookieDeclaration"
    script.type = "text/javascript"
    script.src =
      "https://consent.cookiebot.com/f9f2a283-5c64-4e53-acab-d8c58ae41abe/cd.js"
    script.async = true

    wrap.appendChild(script)
    return () => {
      wrap.removeChild(script)
    }
  }, [])
  return (
    <Layout title="Privacy and cookie policy" location={props.location}>
      <Section>
        <PrivacyContainer narrow="true">
          <h1>Privacy and cookie policy</h1>
          <p>
            <AccentText>.action</AccentText> respects your privacy. Here you can
            find information on what personal information we collect and how we
            use the information.
          </p>
          <h2>What data do we collect?</h2>
          <p>
            We process only personal data that you provide us yourself via our
            contact form. A list of personal data that we collect and process is
            below:
          </p>
          <ul>
            <li>First name</li>
            <li>Last name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Company name</li>
            <li>Other personal data that you provided yourself</li>
          </ul>
          <p>
            Our website also uses cookies (small text files that is stored on
            your device when you visit our website). We use the cookies to save
            your preferences, deliver you the most relevant content, analyze and
            optimize your experience.
          </p>
          <h2>How is your data used?</h2>
          <p>
            We use your personal data to contact you regarding the subject of
            your interest and provide you the best service.
          </p>
          <h2>Do we share your data with someone?</h2>
          <p>
            We don't share your personal data with any third parties, unless
            this is necessary for execution of our agreement with you or is a
            legal obligation.
          </p>
          <h2>Security of data</h2>
          <p>
            We care about protection of your personal data. We apply appropriate
            measures in order to prevent loss, misuse, disclosure, unauthorized
            access and modification of your personal information.
            <br />
            If you are EU resident you have a right to request deletion of your
            personal data. If you want your data to be removed or you have any
            other request about it, please <Link to="/contact">contact us</Link>
            .
          </p>
          <h2>Security of data</h2>
          <p>
            We care about protection of your personal data. We apply appropriate
            measures in order to prevent loss, misuse, disclosure, unauthorized
            access and modification of you personal information.
            <br />
            If you are EU resident you have a right to request deletion of your
            personal data. If you want your data to be removed or you have any
            other request about it, please <Link to="/contact">contact us</Link>
            .
          </p>
          <h2>Cookies</h2>
          <p id="cookies-declaration-wrap"></p>
        </PrivacyContainer>
      </Section>
    </Layout>
  )
}
export default PrivacyPage

const PrivacyContainer = styled(Container)`
  p {
    ${(props) => props.theme.font_size.large};
    line-height: 2.5rem;
    text-align: justify;
    overflow-wrap: break-word;
    @media (max-width: ${(props) => props.theme.screen.sm}) {
      ${(props) => props.theme.font_size.regular}
    }

    #CookieDeclarationConsentIdAndDate {
      word-break: break-all;
    }
  }
  h1 {
    ${(props) => props.theme.font_size.larger};
  }

  h1,
  h2 {
    text-align: center;
  }
`
